const IconKofi = () => {
  return (
    <svg
      className="Icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 13.12"
      fill="none"
      stroke="currentColor"
    >
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3">
        <path d="M15.54,7.29a5.87,5.87,0,0,1-1.33,0V2.82h.9a2,2,0,0,1,2.06,2.09,2.21,2.21,0,0,1-1.63,2.38m3.87-3.15a4.34,4.34,0,0,0-1.78-2.8A4.8,4.8,0,0,0,14.91.5H1.17a.72.72,0,0,0-.67.71s0,.15,0,.15,0,6.08,0,9.33A2,2,0,0,0,2.58,12.6l9.28,0a3,3,0,0,0,.42-.05,2.65,2.65,0,0,0,1.87-2.91c3.44.19,5.87-2.24,5.26-5.47" />
        <path
          fill="currentColor"
          stroke="none"
          d="M7.24,10.08a.19.19,0,0,0,.24,0s2.19-2,3.17-3.14a2.1,2.1,0,0,0-.57-3.41,2.57,2.57,0,0,0-2.74.76A2.41,2.41,0,0,0,3.89,4,2.43,2.43,0,0,0,4,6.88C4.49,7.6,6.8,9.66,7.15,10l.09.07"
        />
      </g>
    </svg>
  );
};

export { IconKofi };
