import styles from "./index.module.css";

const Logo = () => {
  return (
    <svg className={styles.wrapper} viewBox="0 0 441.8 668.86">
      <defs>
        <linearGradient id="gr1" x1="0" x2="0" y1="0" y2="1">
          <stop offset="20%" stopColor="#442111" />
          <stop offset="70%" stopColor="#854f21" />
        </linearGradient>
        <clipPath id="clip-path" transform="translate(-179.77 -61.69)">
          <path
            fill="none"
            d="M269.89,361.6S276.23,388.72,293,390s31.79,4.85,48.76,2.58,28.57-4.94,28.57-4.94-10.36-11.84-21.78-17.7-31.5-12.29-49.39-10.62S269.89,361.6,269.89,361.6Z"
          />
        </clipPath>
      </defs>
      <g
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="10px"
      >
        <polyline points="221.06 31.88 268.89 5.66 392.4 111.19 434.95 226.8 439.01 280.8 423.41 327.47" />
        <polyline points="316.95 46.72 355.86 172.25 329.97 233.01 333.29 166.78 262.25 35.42 223.08 107.89 292.27 90.92" />
        <polyline points="213.8 471.8 242 465.18 289.47 497.33 254.02 529.34 214.06 529.34 214.06 499.33 289.47 497.33" />
        <polyline points="218.57 588.29 289.47 588.29 355.86 521.78 381.08 420.35 406.31 418.87 423.16 329.27 391.7 325.28 387.06 282.8 341.92 257.57 256.94 280.81 241.01 314.49 256.94 420.35 218.57 429.25" />
        <polyline points="387.06 282.8 371.29 171.05 338.84 117.35" />
        <polyline points="307.3 570.43 323.94 666.09 209.8 665.79" />
        <polyline points="220.82 314.49 239.61 314.39 276.42 329.01" />
        <path
          d="M398.33,342.76c.8-.35,38.38-.26,38.38-.26l20.29,48h95.77L535.62,583.47"
          transform="translate(-179.77 -61.69)"
        />
        <polyline points="223.08 107.89 340.75 252.38 334.17 223.14" />
        <polyline points="277.24 328.85 257.9 418.87 223.08 408.49" />
        <line x1="257.9" y1="418.87" x2="316.39" y2="448.41" />
        <polyline points="219.75 666.5 289.47 588.29 316.39 448.41 373 328.85 383.48 280.8" />
      </g>
      <polygon
        fill="#e3a76a"
        points="223.07 46.91 223.07 668.62 121.97 668.62 139.58 601.97 147.64 571.45 145.6 569.41 94.68 518.49 65.79 414.17 40.12 402.94 40.12 333.56 61.72 112.81 133.57 45.42 164.77 40.18 179.91 41.93 189.63 43.05 223.07 46.91"
      />
      <g>
        <polygon
          fill="#fbcb7f"
          points="85.32 328.85 196.3 332.39 188.67 418.88 85.32 328.85"
        />
        <path
          fill="#b7874d"
          d="M307.08,332.11s27,20.92,39.3,28.92c7.9,5.14,17.37,19.81,23.61,26.21a113.47,113.47,0,0,1-15.55,3.43c-16.42,2.64-33.15,3.41-33.15,3.41h54.78V347.19Z"
          transform="translate(-179.77 -61.69)"
        />
        <path
          fill="#6b421e"
          d="M265.09,359a121.08,121.08,0,0,1,26.48-9.26,114.48,114.48,0,0,1,14-2.24c2.36-.15,4.72-.35,7.1-.3s4.76.12,7.15.26a44,44,0,0,1,14,3.79,57.82,57.82,0,0,1,12.14,7.55,76.34,76.34,0,0,1,10.17,9.84,73,73,0,0,1,8.15,11.44,108.87,108.87,0,0,0-9.46-10.19,93.14,93.14,0,0,0-10.58-8.8,62.64,62.64,0,0,0-11.82-6.65,41.88,41.88,0,0,0-12.91-3.32A91.27,91.27,0,0,0,305.8,351c-4.57.3-9.13.86-13.68,1.59A217,217,0,0,0,265.09,359Z"
          transform="translate(-179.77 -61.69)"
        />
        <g clipPath="url(#clip-path)">
          <path
            fill="#8ba097"
            d="M269.89,361.6S276.23,388.72,293,390s31.79,4.85,48.76,2.58,28.57-4.94,28.57-4.94-10.36-11.84-21.78-17.7-31.5-12.29-49.39-10.62S269.89,361.6,269.89,361.6Z"
            transform="translate(-179.77 -61.69)"
          />
          <circle fill="url(#gr1)" cx="138.59" cy="313.04" r="19.16" />
          <circle fill="#150f0a" cx="138.59" cy="313.04" r="7.9" />
        </g>
        <path
          fill="#442111"
          d="M370.36,387.63S350.38,357,312,355.84c-40-1.16-49.22,1.74-49.22,1.74s6.2,25.05,23.42,31.13c35.92,12.68,84.15-1.08,84.15-1.08s-59.32,8.74-79.06-1.32c-15.22-7.76-17.56-19.42-15-21.82s36.22-4,56.72,1C354.44,370.64,370.36,387.63,370.36,387.63Z"
          transform="translate(-179.77 -61.69)"
        />
        <path
          fill="#c6e3f7"
          opacity="0.42"
          mix-blend-mode="screen"
          d="M351.17,377.85a329.37,329.37,0,0,0-36.52-5.18c-11.55-1-31.6-.81-31.6-.81s16.66-5.59,28.25-5.59C333.29,366.27,351.17,377.85,351.17,377.85Z"
          transform="translate(-179.77 -61.69)"
        />
        <circle fill="#c6e3f7" cx="147.58" cy="312.44" r="5.22" />
        <path
          fill="#462112"
          d="M402.84,89.33v80.25l-70.28-18.19L291.3,238.63v53.49L278.06,233.7l5-44.45s-42.83,90.53-47,102.87,0,103.13,0,103.13h-16.2l-30.51-46.18-9.61-38.69L221.49,164.5l81.85-67.39,56.84-35.42Z"
          transform="translate(-179.77 -61.69)"
        />
        <polygon
          fill="#c37b4b"
          points="223.08 408.49 223.08 429.25 213.26 426.29 204.01 423.5 188.67 418.88 223.08 408.49"
        />
        <polygon
          fill="#fbcb7f"
          points="223.08 380.93 214.06 396.3 223.08 396.3 223.08 380.93"
        />
        <polygon
          fill="#fbcb7f"
          points="223.08 314.49 218.57 347.51 223.08 370.26 223.08 314.49"
        />
        <polygon
          fill="#8d5a28"
          points="213.26 426.29 204.01 423.5 212.41 420.35 213.26 426.29"
        />
        <polygon
          fill="#8d5a28"
          points="223.08 466.84 223.08 531.77 198.9 531.77 159.67 497.33 204.01 462.89 223.08 466.84"
        />
        <polygon
          fill="#442815"
          points="223.08 470.53 223.08 497.99 202.65 492.79 187.35 497.33 159.67 497.33 204.01 466.6 223.08 470.53"
        />
        <polygon
          fill="#e2a668"
          points="189.88 505.88 202.16 499.33 223.08 504.57 223.08 509.81 204.01 507.68 189.88 505.88"
        />
        <polygon
          fill="#663421"
          points="111.53 176.94 184.45 32.98 180.41 0 111.53 176.94"
        />
        <polygon
          fill="#663421"
          points="223.08 27.64 223.08 107.89 184.44 32.99 180.41 0 223.08 27.64"
        />
        <polygon
          fill="#442111"
          points="196.3 279.55 119.27 248.69 76.99 261.4 119.59 259.42 196.3 292.14 196.3 279.55"
        />
        <polygon
          fill="#fbcb7f"
          points="223.08 300.02 196.3 279.55 119.27 248.69 223.08 107.89 223.08 300.02"
        />
        <polygon
          fill="#fbcb7f"
          points="223.08 576.88 182.12 576.88 198.74 541.54 223.08 541.54 223.08 576.88"
        />
        <polygon
          fill="#cc9562"
          points="223.08 588.35 223.08 620.64 139.58 601.97 147.64 571.45 174.69 587.7 223.08 588.35"
        />
      </g>
    </svg>
  );
};

export { Logo };
